import {Endpoint, PropublicaEndpoint} from '../Endpoint';
import {ICreateMySubscription, IOrganization} from '../Types';

interface ICreateOrgUser {
  email: string;
  user_type?: string;
  user_status?: string;
}

interface IUpdateOrgUser {
  id: string;
  email: string;
  user_type?: string;
  user_status?: string;
}

interface IRemoveOrgUser {
  id: string;
}

interface IProductMyOrganization {
  targetId: string;
  targetType: string;
}

interface IUpdatePaymentMethod {
  paymentMethodId: string;
}

interface ICancelMySubscription {
  reason?: string;
}

interface IFinalizeMySubscription {
  paymentIntent: any;
  primary_email: string;
}

export const updateMyOrganization = (params: any) => Endpoint.post('/v2-updatemyorganization', params).then((r) => r.data);

export const createMySubscription = (params: any) =>
  Endpoint.post('/v2-createmysubscription', params).then((r: {data: ICreateMySubscription}) => r.data);

export const updateMySubscription = (params: any) => Endpoint.post('/v2-updatemysubscription', params).then((r) => r.data);

export const createOrgUser = (params: ICreateOrgUser) => Endpoint.post('/v2-createorguser', params).then((r) => r.data);

export const getOrgUsers = () => Endpoint.get('/v2-getorgusers').then((r) => r.data);

export const removeOrgUser = (params: IRemoveOrgUser) => Endpoint.post('/v2-removeorguser', params).then((r) => r.data);

export const updateOrgUser = (params: IUpdateOrgUser) => Endpoint.post('/v2-updateorguser', params).then((r) => r.data);

export const addProductToMyOrganization = (params: IProductMyOrganization) =>
  Endpoint.post('/v2-addproducttomyorganization', params).then((r) => r.data);

export const removeProductFromMyOrganization = (params: IProductMyOrganization) =>
  Endpoint.post('/v2-removeproductfrommyorganization', params).then((r) => r.data);

export const clearMyOrganizationProducts = () => Endpoint.post('/v2-clearmyorganizationproducts').then((r) => r.data);

export const listSubscriptionInvoices = () => Endpoint.get('/v2-listsubscriptioninvoices').then((r) => r.data?.data);

export const getSubscriptionPaymentMethod = () => Endpoint.get('/v2-getsubscriptionpaymentmethod').then((r) => r.data);

export const cancelMySubscription = (params: ICancelMySubscription) =>
  Endpoint.post('/v2-cancelmysubscription', params).then((r) => r.data);
export const getSetupIntent = () => Endpoint.get('/v2-getsetupintent').then((r) => r.data);

export const updateMyPaymentMethod = (params: IUpdatePaymentMethod) =>
  Endpoint.post('/v2-updatemypaymentmethod', params).then((r) => r.data);

export const finalizeMySubscription = (params: IFinalizeMySubscription) =>
  Endpoint.post('/v2-finalizemysubscription', params).then((r) => r.data);

export const getPropublicaNonprofitSearch = (params: any) => PropublicaEndpoint.get(`/search.json`, {params}).then((r) => r.data);

export const getSearchPropublica = (params: any) => Endpoint.get(`/v2-searchpropublica`, {params}).then((r) => r.data);

export const getPropublicaOrg = (params: any) => Endpoint.get(`/v2-getpropublicaorg`, {params}).then((r) => r.data);

export const checkForDuplicateOrg = (params: any) => Endpoint.get(`/v2-checkforduplicateorg`, {params}).then((r) => r.data);

export const checkForDuplicateEmail = (params: any) => Endpoint.get(`/v2-checkforduplicateemail`, {params}).then((r) => r.data);

export const checkForExistingEmailDomain = (params: any) =>
  Endpoint.get<{domain?: String; organization?: IOrganization}>(`/v2-checkforexistingemaildomain`, {params}).then((r) => r.data);
